<label>{{ label() }}</label>

<textarea
  [placeholder]="placeholderVisible() ? placeholder() : ''"
  [rows]="rows()"
  [ngModel]="value()"
  (ngModelChange)="onNativeChange($event)"
  [disabled]="disabled()"
  #nativeRef
></textarea>
