import {Component} from '@angular/core';
import {DrawerService} from "../../providers/drawer.service";
import {DemoDrawerExampleComponent} from "./demo-drawer-example/demo-drawer-example.component";

@Component({
  selector: 'app-demo-drawer',
  templateUrl: './demo-drawer.component.html',
  styleUrl: './demo-drawer.component.scss'
})
export class DemoDrawerComponent {
  public constructor(
    private readonly drawerService: DrawerService,
  ) {
  }

  public open(): void {
    this
      .drawerService
      .open(DemoDrawerExampleComponent, {
        payload: {
          message: 'Hello',
        },
      })
      .afterClosed
      .subscribe(value => {
        console.log(value);
      });
  }
}
