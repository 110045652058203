<div class="section">
  <div class="section__heading">
    Breadcrumb
    (<a (click)="push()">push</a> / <a (click)="pop()">pop</a>)
  </div>

  <div class="section__content">
    <app-breadcrumb/>
  </div>
</div>

<ng-template #item1>Hello</ng-template>
<ng-template #item2>World</ng-template>
