import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  forwardRef,
  HostListener,
  inject,
  input,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {fromEvent} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

type Type = 'email' | 'text' | 'number';

type Value = string;

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.ng-disabled]': 'disabled()',
    '[class.ng-filled]': 'value()',
    '[class.ng-focused]': 'focused()',
    '[class.ng-touched]': 'touched()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, ControlValueAccessor {
  public readonly disabled = signal<boolean>(false);
  public readonly focused = signal<boolean>(false);
  public readonly label = input.required<string>();
  public readonly native = viewChild.required<ElementRef<HTMLInputElement>>('nativeRef');
  public readonly placeholder = input<string>();
  public readonly placeholderVisible = computed<boolean>(() => {
    return typeof this.placeholder() === 'string' && this.focused();
  });
  public readonly touched = signal<boolean>(false);
  public readonly type = input<Type>('text');
  public readonly value = signal<Value>('');

  private onChange?: (value: Value) => void;
  private onTouched?: () => void;

  private readonly destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    fromEvent(this.native().nativeElement, 'focus')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.disabled()) {
          return;
        }

        this.focused.set(true);
      });

    fromEvent(this.native().nativeElement, 'blur')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.disabled()) {
          return;
        }

        this.value.update(value => value.trim());

        if (typeof this.onChange === 'function') {
          this.onChange(this.value());
        }

        this.focused.set(false);

        if (typeof this.onTouched === 'function') {
          this.onTouched();
        }
      });
  }

  @HostListener('click')
  public onHostClick(): void {
    this.native().nativeElement.focus();
  }

  public onNativeChange(value: Value): void {
    this.value.set(value);

    if (typeof this.onChange === 'function') {
      this.onChange(value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: Value): void {
    if (typeof value === 'string') {
      this.value.set(value);
    } else {
      throw new Error('The value should be a string');
    }
  }
}
