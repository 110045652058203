<div class="section">
  <div class="section__heading">
    Empty
  </div>

  <div class="section__content">
    <app-empty icon="stop"
               heading="You have no entries in your history"
               description="Your history is empty because you haven't performed any operation yet."/>
  </div>
</div>
