<div class="section">
  <div class="section__heading">
    Description
  </div>

  <div class="section__content">
    <div>
      <app-description key="Username" value="Partyka"/>
      <app-description key="E-mail" value="hello@partyka.dev"/>
    </div>
  </div>
</div>
