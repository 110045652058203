@if (icon()) {
  <div class="menu-item__icon">
    <app-icon [name]="icon()!"/>
  </div>
}

<div>
  <div class="menu-item__heading">{{ heading() }}</div>
  <div class="menu-item__description">{{ description() }}</div>
</div>
