<div class="section">
  <div class="section__heading">
    Tooltip
  </div>

  <div class="section__content">
    <div class="section__row section__row--center">
      <button appTooltip="Hello World" appTooltipPlacement="top">top</button>
      <button appTooltip="Hello World" appTooltipPlacement="right">right</button>
      <button appTooltip="Hello World" appTooltipPlacement="bottom">bottom</button>
      <button appTooltip="Hello World" appTooltipPlacement="left">left</button>
    </div>
  </div>
</div>
