import {ChangeDetectionStrategy, Component, forwardRef, HostListener, input, signal} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

type Value = boolean;

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrl: './toggle.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.ng-disabled]': 'disabled()',
    '[class.ng-checked]': 'value()'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  public readonly disabled = signal<boolean>(false);
  public readonly label = input<string>();
  public readonly value = signal<Value>(false);

  public registeredOnChange?: (value: any) => void;
  public registeredOnTouched?: () => void;

  public onToggle(): void {
    if (this.disabled()) {
      return;
    }

    this.value.update((value: boolean) => !value);

    if (typeof this.registeredOnChange === 'function') {
      this.registeredOnChange(this.value);
    }

    if (typeof this.registeredOnTouched === 'function') {
      this.registeredOnTouched();
    }
  }

  @HostListener('blur')
  public onBlur(): void {
    if (typeof this.registeredOnTouched === 'function') {
      this.registeredOnTouched();
    }
  }

  public registerOnChange(fn: any): void {
    this.registeredOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.registeredOnTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: Value): void {
    if (typeof value === 'boolean') {
      this.value.set(value);
    } else {
      throw new Error('The value should be boolean');
    }
  }
}
