import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {Icon} from '../icon/icon.registry';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrl: './empty.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent {
  public readonly description = input.required<string>();
  public readonly heading = input.required<string>();
  public readonly icon = input.required<Icon>();
}
