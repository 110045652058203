import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {default as icons, type Icon} from "./icon.registry";

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[innerHTML]': 'svg()',
  },
})
export class IconComponent {
  public readonly name = input.required<Icon>();
  public readonly svg = computed<SafeHtml>(() => {
    return this.domSanitizer.bypassSecurityTrustHtml(icons[this.name()]);
  });

  private readonly domSanitizer = inject(DomSanitizer)
}
