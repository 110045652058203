import {ChangeDetectionStrategy, Component, viewChild} from '@angular/core';
import {CdkPortal} from "@angular/cdk/portal";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'appMenu',
})
export class MenuComponent {
  public readonly cdkPortal = viewChild.required(CdkPortal);
}
