import {Component} from '@angular/core';

@Component({
  selector: 'app-demo-tooltip',
  templateUrl: './demo-tooltip.component.html',
  styleUrl: './demo-tooltip.component.scss'
})
export class DemoTooltipComponent {

}
