import {Injectable, TemplateRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface Breadcrumb {
  templateRef: TemplateRef<any>;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  public readonly breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);

  public push(templateRef: TemplateRef<any>): void {
    this.breadcrumbs.next([...this.breadcrumbs.value, {templateRef}]);
  }

  public remove(templateRef: TemplateRef<any>): void {
    this.breadcrumbs.next(this.breadcrumbs.value.filter(breadcrumb => breadcrumb.templateRef !== templateRef));
  }
}
