@if (src) {
  @defer {
    <img [src]="src" alt=""/>
  } @placeholder (minimum 500ms) {
    <ng-container *ngTemplateOutlet="skeletonRef"></ng-container>
  } @loading (minimum 500ms) {
    <ng-container *ngTemplateOutlet="skeletonRef"></ng-container>
  } @error {
    <ng-container *ngTemplateOutlet="placeholderRef"></ng-container>
  }
} @else {
  <ng-container *ngTemplateOutlet="placeholderRef"></ng-container>
}

<ng-template #placeholderRef>
  <div class="placeholder">
    <app-icon name="user"/>
  </div>
</ng-template>

<ng-template #skeletonRef>
  <app-skeleton [radius]="radius"
                [height]="'100%'"
                [width]="'100%'"/>
</ng-template>
