import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Modal} from "../../../providers/modal.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

type ModalInput = { message: string };

type ModalOutput = string | undefined;

@Component({
  selector: 'app-demo-modal-example',
  templateUrl: './demo-modal-example.component.html',
  styleUrl: './demo-modal-example.component.scss'
})
export class DemoModalExampleComponent extends Modal<ModalInput, ModalOutput> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);

  public ngOnInit(): void {
    this
      .overlay
      .backdropClick()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.close('closed by backdrop'));
  }
}
