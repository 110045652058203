import {animate, AnimationBuilder, style} from '@angular/animations';
import {ComponentType, Overlay, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {inject, Injectable, Injector} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export abstract class Drawer<Input = any, Output = any> {
  public overlay!: OverlayRef;
  public payload!: Input;

  /**
   * @description do not use this property directly, use `close` method instead
   */
  public onClose!: Subject<Output>;

  public close(output: Output): void {
    this.onClose.next(output);
    this.onClose.complete();
  }
}

export interface DrawerRef<Output = any> {
  afterClosed: Observable<Output>;
}

export interface DrawerOptions<Input> {
  payload: Input;
}

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private readonly animationBuilder = inject(AnimationBuilder);
  private readonly injector = inject(Injector);
  private readonly overlay = inject(Overlay);
  private readonly overlayQueue = new Set<OverlayRef>();

  public open<Input = any, Output = any>(component: ComponentType<Drawer<Input, Output>>, options: DrawerOptions<Input>): DrawerRef<Output> {
    const overlayRef: OverlayRef = this.overlay.create({
      disposeOnNavigation: true,
      hasBackdrop: true,
      backdropClass: this.overlayQueue.size === 0 ? ['drawer-backdrop-primary'] : ['drawer-backdrop-secondary'],
      panelClass: ['drawer-panel'],
      positionStrategy: this.overlay.position().global().top('0').right('0').bottom('0'),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    const afterClosed: Subject<Output> = new Subject<Output>();

    const onClose: Subject<Output> = new Subject<Output>();

    onClose
      .pipe(takeUntil(overlayRef.detachments()))
      .subscribe(result => {
        this.overlayQueue.delete(overlayRef);

        this.calculateWidth();

        overlayRef.detachBackdrop();

        this
          .hideDrawer(overlayRef.overlayElement)
          .subscribe(() => {
            if (overlayRef.hasAttached()) {
              overlayRef.dispose();
            }

            afterClosed.next(result);
            afterClosed.complete();
          });
      });

    const componentRef = overlayRef.attach(new ComponentPortal(component, undefined, Injector.create({
      parent: this.injector,
      providers: [],
    })));

    componentRef.instance.onClose = onClose;
    componentRef.instance.overlay = overlayRef;
    componentRef.instance.payload = options.payload;

    this.overlayQueue.add(overlayRef);

    this.calculateWidth();

    this
      .showDrawer(overlayRef.overlayElement)
      .subscribe();

    return {
      afterClosed: afterClosed.asObservable(),
    };
  }

  private showDrawer(element: HTMLElement): Observable<void> {
    return new Observable(subscriber => {
      const player = this
        .animationBuilder
        .build([
          style({transform: 'translateX(100%)'}),
          animate('400ms cubic-bezier(0.3, 0, 0, 1)', style({transform: 'translateX(0)'})),
        ])
        .create(element);

      player.onDone(() => {
        subscriber.next();
        subscriber.complete();
      });

      player.play();
    });
  }

  private hideDrawer(element: HTMLElement): Observable<void> {
    return new Observable(subscriber => {
      const player = this
        .animationBuilder
        .build([
          style({transform: 'translateX(0)'}),
          animate('250ms cubic-bezier(0.3, 0, 0, 1)', style({transform: 'translateX(100%)'})),
        ])
        .create(element);

      player.onDone(() => {
        subscriber.next();
        subscriber.complete();
      });

      player.play();
    });
  }

  private calculateWidth(): void {
    [...this.overlayQueue.values()].reverse().forEach((it, index) => {
      this
        .animationBuilder
        .build([
          animate('250ms cubic-bezier(0.3, 0, 0, 1)', style({transform: `translateX(-${index * 50}px)`})),
        ])
        .create(it.overlayElement)
        .play();
    });
  }
}
