import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-demo-calendar',
  templateUrl: './demo-calendar.component.html',
  styleUrl: './demo-calendar.component.scss',
})
export class DemoCalendarComponent {
  public formGroup = new FormGroup({
    control1: new FormControl<boolean>({value: false, disabled: false}, {
      validators: [Validators.required],
    }),
    control2: new FormControl<boolean>({value: false, disabled: true}, {
      validators: [Validators.required],
    }),
  });
}
