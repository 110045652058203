import {inject, Injectable, Injector, TemplateRef} from '@angular/core';
import {Overlay, OverlayRef, PositionStrategy} from "@angular/cdk/overlay";
import {animate, AnimationBuilder, style} from "@angular/animations";
import {ComponentPortal} from "@angular/cdk/portal";
import {ToastComponent} from "../components/toast/toast.component";
import {fromEvent, map, merge, startWith, Subscription, switchMap, timer} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private readonly animationBuilder = inject(AnimationBuilder);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly injector = inject(Injector);
  private readonly overlay = inject(Overlay);
  private readonly overlayQueue = new Set<OverlayRef>();
  private overlayQueueTimer?: Subscription;

  public get isHandset(): boolean {
    return this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait);
  }

  public get positionStrategy(): PositionStrategy {
    if (this.isHandset) {
      // Mobile
      return this.overlay.position().global().bottom('24px').centerHorizontally();
    }

    // Tablet + Web
    return this.overlay.position().global().bottom('24px').right('24px');
  }

  public open(content: string | TemplateRef<any>): void {
    this.overlayQueueTimer?.unsubscribe();

    const overlayRef: OverlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      disposeOnNavigation: true,
      panelClass: ['toast-panel'],
      hasBackdrop: false,
      backdropClass: '',
    });

    this
      .breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])
      .pipe(takeUntil(overlayRef.detachments()))
      .subscribe(() => overlayRef.updatePositionStrategy(this.positionStrategy))

    const componentRef = overlayRef
      .attach(new ComponentPortal(ToastComponent, undefined, this.injector));

    componentRef.instance.content = content;

    this.overlayShow(overlayRef);
  }

  private overlayShow(overlayRef: OverlayRef): void {
    const player = this
      .animationBuilder
      .build([
        style({opacity: 0.5, transform: 'translateY(50%)'}),
        animate('400ms cubic-bezier(0.3, 0, 0, 1)', style({opacity: 1, transform: 'translateY(0)'})),
      ])
      .create(overlayRef.overlayElement);

    player.onStart(() => {
      this.overlayQueue.add(overlayRef);

      this.overlayQueueAnimate();

      this.overlayQueueTimerStart(overlayRef);
    });

    player.play();
  }

  private overlayHide(overlayRef: OverlayRef): void {
    const player = this
      .animationBuilder
      .build([
        style({opacity: 1, transform: 'translateY(0)'}),
        animate('400ms cubic-bezier(0.3, 0, 0, 1)', style({opacity: 0, transform: 'translateY(50%)'})),
      ])
      .create(overlayRef.overlayElement);

    player.onDone(() => {
      overlayRef.detachBackdrop();

      if (overlayRef.hasAttached()) {
        overlayRef.dispose();
      }
    });

    player.onStart(() => {
      this.overlayQueue.delete(overlayRef);

      this.overlayQueueAnimate();

      if (this.overlayQueue.size >= 1) {
        this.overlayQueueTimerStart([...this.overlayQueue.values()][this.overlayQueue.size - 1]);
      }
    });

    player.play();
  }

  private overlayQueueTimerStart(overlayRef: OverlayRef): void {
    const mouseEnter$ = fromEvent(overlayRef.overlayElement, 'mouseenter')
      .pipe(map(() => 'pause'), takeUntil(overlayRef.detachments()));

    const mouseLeave$ = fromEvent(overlayRef.overlayElement, 'mouseleave')
      .pipe(map(() => 'resume'), takeUntil(overlayRef.detachments()));

    this.overlayQueueTimer = merge(mouseEnter$, mouseLeave$)
      .pipe(startWith('resume'), switchMap(state => state === 'resume' ? timer(3000) : []))
      .subscribe(() => this.overlayHide(overlayRef));
  }

  private overlayQueueAnimate(): void {
    [...this.overlayQueue.values()].reverse().forEach((it, index) => {
      let transform: string;
      let opacity: number;

      switch (index) {
        case 0:
        case 1:
        case 2:
          transform = `translateY(-${index * 16}px) scale(${1 - index * 0.05})`;
          opacity = 1;
          break;
        default:
          transform = `translateY(-32px) scale(0.90)`;
          opacity = 0;
          break;
      }

      this
        .animationBuilder
        .build([
          animate('250ms cubic-bezier(0.3, 0, 0, 1)', style({transform: transform, opacity: opacity})),
        ])
        .create(it.overlayElement)
        .play();
    });
  }
}
