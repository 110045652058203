import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-demo-time-picker',
  templateUrl: './demo-time-picker.component.html',
  styleUrl: './demo-time-picker.component.scss',
})
export class DemoTimePickerComponent {
  public formGroup = new FormGroup({
    control1: new FormControl<boolean>({value: false, disabled: false}, {
      validators: [Validators.required],
    }),
    control2: new FormControl<boolean>({value: false, disabled: true}, {
      validators: [Validators.required],
    }),
  });
}
