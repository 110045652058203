<div class="section">
  <div class="section__heading">
    Checkbox
  </div>

  <div class="section__content" [formGroup]="formGroup">
    <app-checkbox label="Control label 1" formControlName="control1"/>

    <app-checkbox label="Control label 2" formControlName="control2"/>
  </div>
</div>
