<div class="section">
  <div class="section__heading">
    Textarea
  </div>

  <div class="section__content" [formGroup]="formGroup">
    <app-textarea label="Control label 1" placeholder="partyka.dev" formControlName="control1"/>

    <app-textarea label="Control label 2" formControlName="control2"/>
  </div>
</div>
