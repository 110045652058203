<div class="section">
  <div class="section__heading">
    Toggle
  </div>

  <div class="section__content" [formGroup]="formGroup">
    <app-toggle label="Terms" formControlName="control1"/>
    <app-toggle label="Terms invalid" formControlName="control1_invalid"/>

    <app-toggle label="Terms disabled" formControlName="control2"/>

    <app-toggle label="Terms disabled" formControlName="control3"/>
  </div>
</div>
