import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BreadcrumbService} from "../../providers/breadcrumb.service";

@Component({
  selector: 'app-demo-breadcrumb',
  templateUrl: './demo-breadcrumb.component.html',
  styleUrl: './demo-breadcrumb.component.scss'
})
export class DemoBreadcrumbComponent implements OnInit {
  @ViewChild('item1', {static: true})
  public item1!: TemplateRef<any>

  @ViewChild('item2', {static: true})
  public item2!: TemplateRef<any>

  public constructor(
    private readonly breadcrumbService: BreadcrumbService,
  ) {
  }

  public ngOnInit() {
    this.breadcrumbService.push(this.item1);
  }

  public push(): void {
    if (!this.breadcrumbService.breadcrumbs.value.some(it => it.templateRef === this.item2)) {
      this.breadcrumbService.push(this.item2);
    }
  }

  public pop(): void {
    this.breadcrumbService.remove(this.item2);
  }
}
