@if (icon() && iconPlacement() === 'left') {
  @if (loading()) {
    <app-spinner/>
  } @else {
    <app-icon [name]="icon()!"/>
  }
}

@if (loading() && iconPlacement() === undefined) {
  <app-spinner/>
} @else {
  @if (!iconOnly()) {
    <ng-content/>
  }
}

@if (icon() && iconPlacement() === 'right') {
  @if (loading()) {
    <app-spinner/>
  } @else {
    <app-icon [name]="icon()!"/>
  }
}
