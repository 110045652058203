import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderStatusLabel',
  pure: true,
})
export class OrderStatusLabelPipe implements PipeTransform {
  public transform(status: string): string {
    switch (status) {
      case 'ACCEPTED':
        return 'Accepted';
      case 'REJECTED':
        return 'Rejected';
      default:
        return status;
    }
  }
}
