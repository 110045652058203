import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Drawer, DrawerService} from "../../../providers/drawer.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

type DrawerInput = { message: string };

type DrawerOutput = string | undefined;

@Component({
  selector: 'app-demo-modal-example',
  templateUrl: './demo-drawer-example.component.html',
  styleUrl: './demo-drawer-example.component.scss'
})
export class DemoDrawerExampleComponent extends Drawer<DrawerInput, DrawerOutput> implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly drawerService = inject(DrawerService);

  public ngOnInit(): void {
    this
      .overlay
      .backdropClick()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.close('closed by backdrop'));
  }

  public open(): void {
    this
      .drawerService
      .open(DemoDrawerExampleComponent, {
        payload: {
          message: 'World',
        },
      })
      .afterClosed
      .subscribe(value => {
        console.log(value);
      });
  }
}
