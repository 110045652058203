<div class="wrapper">
  <!-- Icons -->
  <app-demo-icon/>

  <!-- Loaders -->
  <app-demo-spinner/>
  <app-demo-skeleton/>

  <!-- Buttons -->
  <app-demo-button-primary/>
  <app-demo-button-secondary/>
  <app-demo-button-tertiary/>

  <!-- Breadcrumbs -->
  <app-demo-breadcrumb/>

  <!-- ??? -->
  <app-demo-status/>

  <!-- Over layers -->
  <app-demo-tooltip/>
  <app-demo-menu/>
  <app-demo-toast/>
  <app-demo-drawer/>
  <app-demo-modal/>

  <!--  ??? -->
  <app-demo-avatar/>
  <app-demo-description/>
  <app-demo-empty/>

  <!-- Forms -->
  <app-demo-input/>
  <app-demo-textarea/>

  <app-demo-select/>
  <app-demo-date-picker/>
  <app-demo-time-picker/>
  <app-demo-calendar/>

  <app-demo-toggle/>
  <app-demo-checkbox/>
  <app-demo-radio/>

  <!-- Dark mode -->
  <!--  <app-demo-theme-switcher />-->
</div>
