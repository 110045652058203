import {ChangeDetectionStrategy, Component, input} from '@angular/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  public static readonly DEFAULT_COLOR = 'rgb(16, 17, 18)';

  public readonly color = input<string>(StatusComponent.DEFAULT_COLOR);
  public readonly label = input<string>();
}
