import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-demo-input',
  templateUrl: './demo-input.component.html',
  styleUrl: './demo-input.component.scss'
})
export class DemoInputComponent {
  public formGroup = new FormGroup({
    control1: new FormControl<string>({value: '', disabled: false}, {
      validators: [Validators.required],
    }),
    control2: new FormControl<string>({value: '', disabled: true}, {
      validators: [Validators.required],
    }),
  });
}
