import {ChangeDetectionStrategy, Component, input} from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrl: './skeleton.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.border-radius]': 'radius()',
    '[style.height]': 'height()',
    '[style.width]': 'width()',
  },
})
export class SkeletonComponent {
  public static readonly DEFAULT_RADIUS = '5px';
  public static readonly DEFAULT_HEIGHT = '24px';
  public static readonly DEFAULT_WIDTH = '160px';

  public readonly radius = input<string>(SkeletonComponent.DEFAULT_RADIUS);
  public readonly height = input<string>(SkeletonComponent.DEFAULT_HEIGHT);
  public readonly width = input<string>(SkeletonComponent.DEFAULT_WIDTH);
}
