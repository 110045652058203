import {Component} from '@angular/core';

@Component({
  selector: 'app-demo-avatar',
  templateUrl: './demo-avatar.component.html',
  styleUrl: './demo-avatar.component.scss'
})
export class DemoAvatarComponent {
  public src?: string;

  public reload(): void {
    this.src = '';

    setTimeout(() => {
      this.src = 'https://api.dicebear.com/7.x/adventurer-neutral/svg';
    }, 300);
  }
}
