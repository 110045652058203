import {Component} from '@angular/core';
import {toSignal} from "@angular/core/rxjs-interop";
import {BreadcrumbService} from "../../providers/breadcrumb.service";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {
  public readonly breadcrumbs = toSignal(this.breadcrumbService.breadcrumbs);

  public constructor(
    private readonly breadcrumbService: BreadcrumbService,
  ) {
  }
}
