import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-demo-toggle',
  templateUrl: './demo-toggle.component.html',
  styleUrl: './demo-toggle.component.scss'
})
export class DemoToggleComponent implements OnInit {
  public readonly formGroup = new FormGroup({
    control1: new FormControl<boolean>({value: false, disabled: false}),
    control1_invalid: new FormControl<boolean>({value: false, disabled: false}, [
      Validators.requiredTrue,
    ]),
    control2: new FormControl<boolean>({value: false, disabled: true}),
    control3: new FormControl<boolean>({value: true, disabled: true}),
  });

  public ngOnInit() {
    this.formGroup.markAllAsTouched();
  }
}
