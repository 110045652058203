import {OverlayRef, Overlay, PositionStrategy, ConnectedPosition} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {ComponentRef, Directive, ElementRef, HostListener, inject, Input, OnDestroy, TemplateRef} from '@angular/core';
import {TooltipComponent} from '../components/tooltip/tooltip.component';

export type TooltipPlacement =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnDestroy {
  private readonly elementRef = inject(ElementRef);
  private readonly overlay = inject(Overlay);
  private overlayRef?: OverlayRef;
  private tooltipRef?: ComponentRef<TooltipComponent>;
  
  @Input()
  public appTooltip?: string | TemplateRef<any>;

  @Input()
  public appTooltipPlacement: TooltipPlacement = 'top';

  protected get attached(): boolean {
    return this.overlayRef?.hasAttached() || false;
  }

  public ngOnDestroy(): void {
    if (this.attached) {
      this.overlayRef?.detach();
      this.tooltipRef = undefined;
    }
  }

  @HostListener('window:resize')
  public onResize(): void {
    if (this.attached) {
      this.overlayRef?.updatePositionStrategy(this.getPositionStrategy());
      this.overlayRef?.updatePosition();
    }
  }

  @HostListener('mouseenter')
  public showTooltip(): void {
    if (!this.attached && this.appTooltip) {
      this.overlayRef = this.overlay.create({
        positionStrategy: this.getPositionStrategy(),
        scrollStrategy: this.overlay.scrollStrategies.reposition({autoClose: false}),
      });

      this.tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
      this.tooltipRef.instance.content = this.appTooltip;
    }
  }

  @HostListener('mouseleave')
  public hideTooltip(): void {
    if (this.attached) {
      this.overlayRef?.detach();
      this.overlayRef = undefined;
    }
  }

  protected getPositionStrategy(): PositionStrategy {
    let originPosition: ConnectedPosition[] = [];

    switch (this.appTooltipPlacement) {
      case 'top':
        originPosition = [
          {originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -10},
        ];
        break;
      case 'right':
        originPosition = [
          {originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center', offsetX: 10},
        ];
        break;
      case 'bottom':
        originPosition = [
          {originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 10},
        ];
        break;
      case 'left':
        originPosition = [
          {originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: -10},
        ];
        break;
      default:
        originPosition = [
          {originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom', offsetY: -10},
        ];
        break;
    }

    return this
      .overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions(originPosition)
      .withPush(false);
  }
}
