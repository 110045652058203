import {Component, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent {
  public content!: string | TemplateRef<any>;

  public isTemplateRef(value: any): value is TemplateRef<any> {
    return this.content instanceof TemplateRef;
  }
}
