import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {type Icon} from '../icon/icon.registry';

@Component({
  selector: 'button[app-menu-item]',
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  public readonly description = input.required<string>();
  public readonly heading = input.required<string>();
  public readonly icon = input<Icon>();
}
