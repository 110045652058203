<div class="section">
  <div class="section__heading">
    Menu
    (
    @for (placement of placements; track $index) {
      <a (click)="appMenuPlacement = placement">{{ placement }}</a>,
    }
    )
  </div>

  <div class="section__content">
    <div class="section__row section__row--center">
      <div>
        <button [appMenu]="appMenuRef" [appMenuPlacement]="appMenuPlacement">click me</button>
      </div>
    </div>

    <!--    <div class="context-area" (contextmenu)="contextMenu($event, menu)">Right Click on here</div>-->

    <app-menu #appMenuRef="appMenu">
      <button app-menu-item icon="globe" heading="Localization" description="Set your language and currency"></button>
      <button app-menu-item icon="gear" heading="Settings" description="Go to settings"></button>
    </app-menu>
  </div>
</div>
