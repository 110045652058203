import {Component} from '@angular/core';
import {default as icon} from "../../components/icon/icon.registry";

@Component({
  selector: 'app-demo-icon',
  templateUrl: './demo-icon.component.html',
  styleUrl: './demo-icon.component.scss'
})
export class DemoIconComponent {
  public readonly icons = Object.keys(icon) as (keyof typeof icon)[];
}
