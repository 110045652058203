import {Component, inject} from '@angular/core';
import {ModalService} from "../../providers/modal.service";
import {DemoModalExampleComponent} from "./demo-modal-example/demo-modal-example.component";

@Component({
  selector: 'app-demo-modal',
  templateUrl: './demo-modal.component.html',
  styleUrl: './demo-modal.component.scss'
})
export class DemoModalComponent {
  private readonly modalService = inject(ModalService);

  public open(): void {
    this
      .modalService
      .open(DemoModalExampleComponent, {
        payload: {
          message: 'Hello',
        },
      })
      .afterClosed
      .subscribe(value => {
        console.log(value);
      });
  }
}
