import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {Router, RouterModule, Routes} from "@angular/router";
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {apiInterceptor} from "./providers/api.interceptor";
import {InputComponent} from './components/input/input.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DemoInputComponent} from './containers/demo-input/demo-input.component';
import {TextareaComponent} from './components/textarea/textarea.component';
import {DemoTextareaComponent} from "./containers/demo-textarea/demo-textarea.component";
import {ToggleComponent} from './components/toggle/toggle.component';
import {DemoToggleComponent} from "./containers/demo-toggle/demo-toggle.component";
import {ButtonComponent} from './components/button/button.component';
import {DemoButtonTertiaryComponent} from "./containers/demo-button-tertiary/demo-button-tertiary.component";
import {DemoButtonPrimaryComponent} from "./containers/demo-button-primary/demo-button-primary.component";
import {DemoButtonSecondaryComponent} from "./containers/demo-button-secondary/demo-button-secondary.component";
import {IconComponent} from './components/icon/icon.component';
import {DemoIconComponent} from "./containers/demo-icon/demo-icon.component";
import {SpinnerComponent} from './components/spinner/spinner.component';
import {DemoSpinnerComponent} from "./containers/demo-spinner/demo-spinner.component";
import {SkeletonComponent} from './components/skeleton/skeleton.component';
import {DemoSkeletonComponent} from "./containers/demo-skeleton/demo-skeleton.component";
import {AvatarComponent} from './components/avatar/avatar.component';
import {DemoAvatarComponent} from "./containers/demo-avatar/demo-avatar.component";
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {DemoCheckboxComponent} from "./containers/demo-checkbox/demo-checkbox.component";
import {EmptyComponent} from './components/empty/empty.component';
import {DemoEmptyComponent} from "./containers/demo-empty/demo-empty.component";
import {SpinnerDirective} from "./directives/spinner.directive";
import {DescriptionComponent} from './components/description/description.component';
import {EmptyPipe} from './pipes/empty.pipe';
import {DemoDescriptionComponent} from "./containers/demo-description/demo-description.component";
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {DemoBreadcrumbComponent} from "./containers/demo-breadcrumb/demo-breadcrumb.component";
import {TooltipComponent} from './components/tooltip/tooltip.component';
import {DemoTooltipComponent} from "./containers/demo-tooltip/demo-tooltip.component";
import {TooltipDirective} from './directives/tooltip.directive';
import {CdkPortal} from "@angular/cdk/portal";
import {MenuComponent} from './components/menu/menu.component';
import {DemoMenuComponent} from "./containers/demo-menu/demo-menu.component";
import {MenuDirective} from './directives/menu.directive';
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {StatusComponent} from './components/status/status.component';
import {DemoStatusComponent} from "./containers/demo-status/demo-status.component";
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {DemoDrawerComponent} from "./containers/demo-drawer/demo-drawer.component";
import {DrawerService} from "./providers/drawer.service";
import {BreadcrumbService} from "./providers/breadcrumb.service";
import {DemoDrawerExampleComponent} from './containers/demo-drawer/demo-drawer-example/demo-drawer-example.component';
import {DemoModalComponent} from "./containers/demo-modal/demo-modal.component";
import {DemoModalExampleComponent} from "./containers/demo-modal/demo-modal-example/demo-modal-example.component";
import {DemoRadioComponent} from "./containers/demo-radio/demo-radio.component";
import {DemoToastComponent} from "./containers/demo-toast/demo-toast.component";
import {DemoCalendarComponent} from "./containers/demo-calendar/demo-calendar.component";
import {DemoDatePickerComponent} from "./containers/demo-date-picker/demo-date-picker.component";
import {DemoTimePickerComponent} from "./containers/demo-time-picker/demo-time-picker.component";
import {DemoSelectComponent} from "./containers/demo-select/demo-select.component";
import {ToastComponent} from "./components/toast/toast.component";
import {SkeletonDirective} from "./directives/skeleton.directive";
import {OrderStatusColorPipe} from './pipes/order-status-color.pipe';
import {OrderStatusLabelPipe} from './pipes/order-status-label.pipe';
import {DemoComponent} from './views/demo/demo.component';
import {NgOptimizedImage} from "@angular/common";
import {HomeComponent} from './views/home/home.component';
import * as Sentry from "@sentry/angular";
import {ProductDetailsComponent} from './views/product-details/product-details.component';
import { LogoComponent } from './components/logo/logo.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'demo',
    component: DemoComponent,
  },
  {
    path: 'products/:productId',
    component: ProductDetailsComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    InputComponent,
    DemoInputComponent,
    DemoToggleComponent,
    DemoTextareaComponent,
    TextareaComponent,
    ToggleComponent,
    ButtonComponent,
    DemoButtonTertiaryComponent,
    DemoButtonPrimaryComponent,
    DemoButtonSecondaryComponent,
    IconComponent,
    DemoIconComponent,
    SpinnerComponent,
    DemoSpinnerComponent,
    SkeletonComponent,
    DemoSkeletonComponent,
    AvatarComponent,
    DemoAvatarComponent,
    CheckboxComponent,
    DemoCheckboxComponent,
    EmptyComponent,
    DemoEmptyComponent,
    SpinnerDirective,
    DescriptionComponent,
    EmptyPipe,
    DemoDescriptionComponent,
    BreadcrumbComponent,
    DemoBreadcrumbComponent,
    TooltipComponent,
    DemoTooltipComponent,
    TooltipDirective,
    DemoMenuComponent,
    MenuDirective,
    StatusComponent,
    DemoStatusComponent,
    MenuComponent,
    MenuItemComponent,
    DemoDrawerComponent,
    DemoDrawerExampleComponent,
    DemoModalComponent,
    DemoModalExampleComponent,
    DemoRadioComponent,
    DemoToastComponent,
    DemoCalendarComponent,
    DemoDatePickerComponent,
    DemoTimePickerComponent,
    DemoSelectComponent,
    ToastComponent,
    SkeletonDirective,
    OrderStatusColorPipe,
    OrderStatusLabelPipe,
    DemoComponent,
    HomeComponent,
    ProductDetailsComponent,
    LogoComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,

    BrowserModule,
    RouterModule.forRoot(routes),

    CdkPortal,
    NgOptimizedImage,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => undefined,
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptors([apiInterceptor])),
    provideAnimationsAsync(),
    BreadcrumbService,
    DrawerService,
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule {
}
