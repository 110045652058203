import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderStatusColor',
  pure: true,
})
export class OrderStatusColorPipe implements PipeTransform {
  public transform(status: string): string {
    switch (status) {
      case 'ACCEPTED':
        return 'green';
      case 'REJECTED':
        return 'red';
      default:
        return 'green';
    }
  }
}
