import {Component} from '@angular/core';
import {MenuPlacement} from "../../directives/menu.directive";
import {Menu} from "@angular/cdk/menu";

@Component({
  selector: 'app-demo-menu',
  templateUrl: './demo-menu.component.html',
  styleUrl: './demo-menu.component.scss'
})
export class DemoMenuComponent {
  public placements: MenuPlacement[] = [
    'topLeft',
    'topCenter',
    'topRight',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
  ];
  public appMenuPlacement: MenuPlacement = 'bottomLeft';
}
