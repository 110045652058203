import {Component, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
})
export class TooltipComponent {
  public content!: string | TemplateRef<any>;

  public isTemplateRef(value: any): value is TemplateRef<any> {
    return this.content instanceof TemplateRef;
  }
}
