<div class="section">
  <div class="section__heading">
    Button tertiary
  </div>

  <div class="section__content">
    <div class="section__row">
      <button app-button-tertiary icon="card" icon-only icon-placement="left" (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-only icon-placement="left" loading (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-only icon-placement="left" disabled (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-only icon-placement="left" loading disabled
              (click)="onClick()">
        Checkout
      </button>
    </div>
    
    <div class="section__row">
      <button app-button-tertiary (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary loading (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary disabled (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary loading disabled (click)="onClick()">
        Checkout
      </button>
    </div>

    <div class="section__row">
      <button app-button-tertiary icon="card" icon-placement="left" (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-placement="left" loading (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-placement="left" disabled (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-placement="left" loading disabled
              (click)="onClick()">
        Checkout
      </button>
    </div>

    <div class="section__row">
      <button app-button-tertiary icon="card" icon-placement="right" (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-placement="right" loading (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-placement="right" disabled (click)="onClick()">
        Checkout
      </button>

      <button app-button-tertiary icon="card" icon-placement="right" loading disabled
              (click)="onClick()">
        Checkout
      </button>
    </div>
  </div>
</div>
