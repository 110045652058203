import {ChangeDetectionStrategy, Component, input} from '@angular/core';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrl: './description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DescriptionComponent {
  public readonly key = input.required<string>();
  public readonly value = input.required<string | number>();
}
