<!--<app-logo/>-->

<div class="product">
  <main>
    <div class="product__header">
      <h1 class="product__name">Angular Design System</h1>
    </div>

    <div class="product__description">
      <p>W tym e-booku nauczysz się, jak tworzyć komponenty klasy enterprise od podstaw. Dowiesz się również, jak
        skutecznie testować te komponenty, aby zapewnić ich wysoką jakość i niezawodność. Przewodnik ten dostarczy Ci
        niezbędnych narzędzi i technik do budowy skalowalnych, profesjonalnych komponentów, które spełnią wymagania
        nowoczesnych aplikacji.</p>
    </div>

    <div class="product__checkout">
      <button app-button-primary icon="card">19.00 USDC</button>

      <div class="product__checkout-hint">
        By continuing you accept the terms and conditions and will be redirected to coinbase checkout
      </div>
    </div>
  </main>

  <aside>
    <div class="product__image">
      <img
        src="https://eduwebendpoint.azureedge.net/cdnblob/images/paths/angular.svg"
        alt="">
    </div>
  </aside>
</div>
