<div class="checkbox__square" (click)="toggle()">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="16" height="16" fill="currentColor">
    <polyline points="216 72 104 184 48 128" fill="none" stroke="currentColor" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="24"></polyline>
  </svg>
</div>

@if (label()) {
  <div class="checkbox__label">{{ label() }}</div>
}
