import {Component} from '@angular/core';

@Component({
  selector: 'app-demo-button-tertiary',
  templateUrl: './demo-button-tertiary.component.html',
  styleUrl: './demo-button-tertiary.component.scss'
})
export class DemoButtonTertiaryComponent {
  public onClick(): void {
    console.log('click');
  }
}
