import {Component} from '@angular/core';

@Component({
  selector: 'app-demo-button-primary',
  templateUrl: './demo-button-primary.component.html',
  styleUrl: './demo-button-primary.component.scss'
})
export class DemoButtonPrimaryComponent {
  public onClick(): void {
    console.log('click');
  }
}
