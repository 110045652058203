import {animate, AnimationBuilder, AnimationMetadata, style} from '@angular/animations';
import {ComponentType, Overlay, OverlayRef, PositionStrategy} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {inject, Injectable, Injector} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

export abstract class Modal<Input = any, Output = any> {
  public overlay!: OverlayRef;
  public payload!: Input;

  /**
   * @description do not use this property directly, use `close` method instead
   */
  public onClose!: Subject<Output>;

  public close(output: Output): void {
    this.onClose.next(output);
    this.onClose.complete();
  }
}

export interface ModalRef<Output = any> {
  afterClosed: Observable<Output>;
}

export interface ModalOptions<Input> {
  payload: Input;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private readonly animationBuilder = inject(AnimationBuilder);
  private readonly injector = inject(Injector);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly overlay = inject(Overlay);
  private readonly overlayQueue = new Set<OverlayRef>();

  public get isHandset(): boolean {
    return this.breakpointObserver.isMatched(Breakpoints.HandsetPortrait);
  }

  public get positionStrategy(): PositionStrategy {
    if (this.isHandset) {
      // Mobile
      return this.overlay.position().global().left('0').bottom('0').right('0');
    }

    // Tablet + Web
    return this.overlay.position().global().centerHorizontally().centerVertically();
  }

  public open<Input = any, Output = any>(component: ComponentType<Modal<Input, Output>>, options: ModalOptions<Input>): ModalRef<Output> {
    const overlayRef: OverlayRef = this.overlay.create({
      disposeOnNavigation: true,
      hasBackdrop: true,
      panelClass: ['modal-panel'],
      positionStrategy: this.positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      backdropClass: this.overlayQueue.size === 0 ? ['modal-backdrop-primary'] : ['modal-backdrop-secondary'],
    });

    this
      .breakpointObserver
      .observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])
      .pipe(takeUntil(overlayRef.detachments()))
      .subscribe(() => overlayRef.updatePositionStrategy(this.positionStrategy))

    const afterClosed: Subject<Output> = new Subject<Output>();

    const onClose: Subject<Output> = new Subject<Output>();

    onClose
      .pipe(takeUntil(overlayRef.detachments()))
      .subscribe(result => {
        this.overlayQueue.delete(overlayRef);

        overlayRef.detachBackdrop();

        this
          .hideModal(overlayRef.overlayElement)
          .subscribe(() => {
            if (overlayRef.hasAttached()) {
              overlayRef.dispose();
            }

            afterClosed.next(result);
            afterClosed.complete();
          });
      });

    const componentRef = overlayRef.attach(new ComponentPortal(component, undefined, Injector.create({
      parent: this.injector,
      providers: [],
    })));

    componentRef.instance.onClose = onClose;
    componentRef.instance.overlay = overlayRef;
    componentRef.instance.payload = options.payload;

    this.overlayQueue.add(overlayRef);

    this
      .showModal(overlayRef.overlayElement)
      .subscribe();

    return {
      afterClosed: afterClosed.asObservable(),
    };
  }

  protected showModal(element: HTMLElement): Observable<void> {
    return new Observable(subscriber => {
      let animation: AnimationMetadata[];

      if (this.isHandset) {
        animation = [
          style({transform: 'translate3d(0, 100%, 0)'}),
          animate('250ms cubic-bezier(0.3, 0, 0, 1)', style({transform: 'matrix(1, 0, 0, 1, 0, 0)'})),
        ];
      } else {
        animation = [
          style({transform: 'scale(0.9)', opacity: 0}),
          animate('250ms cubic-bezier(0.3, 0, 0, 1)', style({transform: 'scale(1)', opacity: 1})),
        ];
      }

      const player = this
        .animationBuilder
        .build(animation)
        .create(element);

      player.onDone(() => {
        subscriber.next();
        subscriber.complete();
      });

      player.play();
    });
  }

  protected hideModal(element: HTMLElement): Observable<void> {
    return new Observable(subscriber => {
      let animation: AnimationMetadata[];

      if (this.isHandset) {
        animation = [
          style({transform: 'matrix(1, 0, 0, 1, 0, 0)'}),
          animate('250ms cubic-bezier(0.3, 0, 0, 1)', style({transform: 'translate3d(0, 100%, 0)'})),
        ];
      } else {
        animation = [
          style({transform: 'scale(1)', opacity: 1}),
          animate('250ms cubic-bezier(0.3, 0, 0, 1)', style({transform: 'scale(0.9)', opacity: 0})),
        ];
      }

      const player = this
        .animationBuilder
        .build(animation)
        .create(element);

      player.onDone(() => {
        subscriber.next();
        subscriber.complete();
      });

      player.play();
    });
  }
}
