@for (breadcrumb of breadcrumbs(); track $index; let last = $last) {
  <div class="breadcrumb__item">
    <ng-container *ngTemplateOutlet="breadcrumb.templateRef"/>
  </div>

  @if (!last) {
    <div class="breadcrumb__separator">
      <svg width="15"
           height="15"
           viewBox="0 0 15 15"
           fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M4.10876 14L9.46582 1H10.8178L5.46074 14H4.10876Z"
              fill="rgba(0, 0, 0, 0.08)"
              fillRule="evenodd"
              clipRule="evenodd"/>
      </svg>
    </div>
  }
}
