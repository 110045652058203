<div class="section">
  <div class="section__heading">
    Icon
  </div>

  <div class="section__content">
    <div class="demo-icon-list">
      @for (icon of icons; track $index) {
        <app-icon [name]="icon"/>
      }
    </div>
  </div>
</div>
