import {
  ChangeDetectionStrategy,
  Component,
  computed,
  forwardRef,
  HostListener,
  input,
  signal,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

type Value = boolean;

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.tabindex]': 'tabindex()',
    '[class.ng-checked]': 'value()',
    '[class.ng-disabled]': 'disabled()',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  public readonly disabled = signal<boolean>(false);
  public readonly label = input<string>();
  public readonly tabindex = computed(() => this.disabled() ? '-1' : '0');
  public readonly value = signal<Value>(false);

  private registeredOnChange?: (value: any) => void;
  private registeredOnTouched?: () => void;

  @HostListener('keydown.space', ['$event'])
  public onKeydownSpace(event: KeyboardEvent): void {
    event.preventDefault();

    this.toggle();
  }

  public toggle(): void {
    if (this.disabled()) {
      return;
    }

    this.value.update((value: boolean) => !value);

    if (typeof this.registeredOnChange === 'function') {
      this.registeredOnChange(this.value);
    }

    if (typeof this.registeredOnTouched === 'function') {
      this.registeredOnTouched();
    }
  }

  public registerOnChange(fn: any): void {
    this.registeredOnChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.registeredOnTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled.set(disabled);
  }

  public writeValue(value: Value): void {
    if (typeof value === 'boolean') {
      this.value.set(value);
    } else {
      throw new Error('The value should be boolean');
    }
  }
}
