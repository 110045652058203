<label>{{ label() }}</label>

<input
  [type]="type()"
  [placeholder]="placeholderVisible() ? placeholder() : ''"
  [ngModel]="value()"
  (ngModelChange)="onNativeChange($event)"
  [disabled]="disabled()"
  #nativeRef
/>
