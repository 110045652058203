import {Component, inject} from '@angular/core';
import {ToastService} from "../../providers/toast.service"; // 👈 import ToastService

@Component({
  selector: 'app-demo-toast',
  templateUrl: './demo-toast.component.html',
  styleUrl: './demo-toast.component.scss',
})
export class DemoToastComponent {
  private readonly toastService = inject(ToastService); // 👈 inject ToastService

  public open(): void {
    // 👇 call the open method
    this
      .toastService
      .open('The Evil Rabbit jumped over the fence. The Evil Rabbit jumped over the fence again.');
  }
}
