<div class="section">
  <div class="section__heading">
    Avatar
    (<a (click)="reload()">img</a>)
  </div>

  <div class="section__content">
    <app-avatar [src]="src"/>
  </div>
</div>
