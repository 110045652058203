import {Component, input} from '@angular/core';
import {type Icon} from "../icon/icon.registry";
import {coerceBooleanProperty} from "@angular/cdk/coercion";

export type ButtonIconPlacement = 'left' | 'right';

@Component({
  selector: 'button[app-button-primary], button[app-button-secondary], button[app-button-tertiary]',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  host: {
    '[class.icon-only]': 'iconOnly()',
    '[class.loading]': 'loading()',
  },
})
export class ButtonComponent {
  public readonly icon = input<Icon>();
  public readonly iconOnly = input(false, {
    alias: 'icon-only',
    transform: (value: boolean | string) => coerceBooleanProperty(value),
  });
  public readonly iconPlacement = input<ButtonIconPlacement>('left', {
    alias: 'icon-placement',
  });
  public readonly loading = input(false, {
    transform: (value: boolean | string) => coerceBooleanProperty(value),
  });
}
