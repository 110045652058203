import {Pipe, type PipeTransform} from '@angular/core';

@Pipe({
  name: 'empty',
  pure: true,
})
export class EmptyPipe implements PipeTransform {
  public transform(value: any): any {
    return (value === undefined || value === null || value === '') ? '-' : value;
  }
}
