import {AnimationBuilder, style, animate} from '@angular/animations';
import {OverlayRef, Overlay, OverlayPositionBuilder, PositionStrategy, ConnectedPosition} from '@angular/cdk/overlay';
import {DestroyRef, Directive, ElementRef, HostListener, inject, Input, OnInit} from '@angular/core';
import {delay, fromEvent, Observable} from 'rxjs';
import {MenuComponent} from '../components/menu/menu.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export type MenuTrigger =
  | 'click'
  | 'hover';

export type MenuPlacement =
  | 'topLeft'
  | 'topCenter'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomCenter'
  | 'bottomRight';

@Directive({
  selector: '[appMenu]',
})
export class MenuDirective implements OnInit {
  private readonly animationBuilder = inject(AnimationBuilder);
  private readonly elementRef = inject(ElementRef);
  private readonly overlay = inject(Overlay);
  private readonly overlayPositionBuilder = inject(OverlayPositionBuilder);
  private readonly destroyRef = inject(DestroyRef);

  @Input({required: true})
  public appMenu!: MenuComponent;

  @Input()
  public appMenuTrigger: MenuTrigger = 'click'; // todo

  @Input()
  public appMenuPlacement: MenuPlacement = 'bottomLeft';

  @Input()
  public appMenuFlexibleConnectedTo?: ElementRef | HTMLElement;

  private readonly overlayRef: OverlayRef = this.overlay.create({
    positionStrategy: this.getPositionStrategy(),
    scrollStrategy: this.overlay.scrollStrategies.reposition(),
    width: '315px',
    height: 'min-content',
    maxHeight: '300px',
    disposeOnNavigation: true,
  });

  public get hasAttached(): boolean {
    return this.overlayRef.hasAttached();
  }

  public ngOnInit(): void {
    fromEvent(document, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.hasAttached) {
          this.close();
        }
      });

    fromEvent(this.elementRef.nativeElement, 'click')
      .pipe(delay(0), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.hasAttached ? this.close() : this.open());
  }

  @HostListener('document:keydown.escape')
  public onDocumentKeydownEscape(): void {
    if (this.hasAttached) {
      this.close();
    }
  }

  private open(): void {
    if (!this.hasAttached) {
      // const width = coerceElement(this.appMenuFlexibleConnectedTo)?.offsetWidth ?? this.elementRef.nativeElement.offsetWidth;

      this.overlayRef.attach(this.appMenu.cdkPortal());
      this.overlayRef.updatePositionStrategy(this.getPositionStrategy());
      // this.overlayRef.updateSize({width: width});

      this
        .animateFadeInDown(this.overlayRef.overlayElement)
        .subscribe();
    }
  }

  private close(): void {
    if (this.hasAttached) {
      this.animateFadeInOut(this.overlayRef.overlayElement).subscribe(() => this.overlayRef.detach());
    }
  }

  private getPositionStrategy(): PositionStrategy {
    let connectedPosition: ConnectedPosition[];

    switch (this.appMenuPlacement) {
      case 'topLeft':
        connectedPosition = [
          {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
          // {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
        ];
        break;
      case 'topCenter':
        connectedPosition = [
          {originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom'},
          {originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top'},
        ];
        break;
      case 'topRight':
        connectedPosition = [
          {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'},
          {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
        ];
        break;
      case 'bottomLeft':
        connectedPosition = [
          {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
          {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
        ];
        break;
      case 'bottomCenter':
        connectedPosition = [
          {originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top'},
          {originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom'},
        ];
        break;
      case 'bottomRight':
        connectedPosition = [
          {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
          {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'},
        ];
        break;
      default:
        connectedPosition = [
          {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
          {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
        ];
        break;
    }

    return this
      .overlayPositionBuilder
      .flexibleConnectedTo(this.appMenuFlexibleConnectedTo ?? this.elementRef)
      .withFlexibleDimensions(true)
      .withPush(false)
      .withGrowAfterOpen(true)
      .withPositions(connectedPosition);
  }

  private animateFadeInDown(element: HTMLElement): Observable<void> {
    return new Observable(subscriber => {
      const player = this
        .animationBuilder
        .build([
          style({transform: 'translateY(-6px)', opacity: 0.5}),
          animate('100ms cubic-bezier(0.3, 0, 0, 1)', style({transform: 'translateY(0)', opacity: 1})),
        ])
        .create(element);

      player.onDone(() => {
        subscriber.next();
        subscriber.complete();
      });

      player.play();
    });
  }

  private animateFadeInOut(element: HTMLElement): Observable<void> {
    return new Observable(subscriber => {
      const player = this
        .animationBuilder
        .build([
          style({transform: 'translateY(0)', opacity: 1}),
          animate('100ms cubic-bezier(0.3, 0, 0, 1)', style({transform: 'translateY(-6px)', opacity: 0})),
        ])
        .create(element);

      player.onDone(() => {
        subscriber.next();
        subscriber.complete();
      });

      player.play();
    });
  }
}
